import React from 'react'
import SaucePour from '../../images/sauce-pour.png';
import Seafood from '../../images/seafood.png';
import Cocktails from '../../images/cocktails.png';
import Mignon from '../../images/mignon.jpg';
import PatrickSteak from '../../images/patricksteak.jpg';
import SaucyPour from '../../images/saucypour.jpg';
import ScallopMeal from '../../images/scallopmeal.jpg';
import Scallops from '../../images/scallops.jpg';
import Seafood2 from '../../images/seafood.jpg';
import Soup from '../../images/soup.jpg';
import Starter from '../../images/starter.jpg';
import Steak from '../../images/steak.jpg';
import SteakMeal from '../../images/steakmeal.jpg';
import Tartar from '../../images/beeftartar.jpg';
import Burrata from '../../images/beetsburrata.jpg';

import {  GalleryContainer,
          GalleryUl,
          GalleryLi,
          GalleryImg
        } from './GalleryTwoElements';

const index = () => {
  return (
      <GalleryContainer>

          <GalleryUl>

             <GalleryLi>
              <GalleryImg src={Scallops} alt="A dish of scallops" loading="lazy" />
            </GalleryLi>

               <GalleryLi>
              <GalleryImg src={Burrata} alt="Buratta" loading="lazy" />
            </GalleryLi>


            <GalleryLi>
              <GalleryImg src={Seafood2} alt="Seafood" loading="lazy" />
            </GalleryLi>

        </GalleryUl>
        
        {/* <AiFillFacebook />
        <FaInstagramSquare /> */}
      </GalleryContainer>
  )
}

export default index
