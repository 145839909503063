import React from 'react'
import {
        AboutHero,
        AboutHeroTextContainer,
        AboutHeroH1,
        AboutContainer, 
        AboutColumnContainer,
        AboutColumnLeft,
        AboutColumnCenter,
        AboutColumnCenterContainer,
        AboutP1,
        AboutP2,
        AboutColumnRight } from './AboutElements';

const About = () => {
    return (
        <AboutContainer>
            <AboutHero> 
                <AboutHeroTextContainer> <AboutHeroH1>  About Us </AboutHeroH1> </AboutHeroTextContainer>
            </AboutHero>

            <AboutColumnContainer>
                <AboutColumnLeft />
                <AboutColumnCenter> 
                    <AboutColumnCenterContainer>   

                        <AboutP1>
                            Established in 2010. Amber Steak House has become a staple not only in the neighborhood of Greenpoint, Brooklyn 
                            but a city wide destination. We have been commited to the finest service, finest quality of meat and food as well as a dedication to
                            serving the best wine and drinks available since we have opened. We have been serving our 
                            community of Greenpoint for many years.
                        </AboutP1>

                        <AboutP2>
                            Our kitchen is a full two-stories, equipped with three refrigerators for beer, seafood, 
                            vegetables and steaks. Our state-of-the-art kitchen also boasts smokers, steamers and 
                            broilers on which the chef can cook multiple steaks at once.
                        </AboutP2>

                    </AboutColumnCenterContainer>
                </AboutColumnCenter>
                <AboutColumnRight />
            </AboutColumnContainer>

        </AboutContainer>
    )
}

export default About;
