import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
 background-color: #8C291B;
`;

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 820px) {
        padding-top: 32px;
    }
`;

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 80px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: white;
 

  

    @media screen and (max-width: 520px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`;

export const FooterLinkTitle = styled.h1`
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    margin-bottom: 16px;
`;

export const FooterLink = styled(Link)`
    color: #646464;
    text-decoration: none;
    margin-bottom: 1.5rem;
    font-size: 14px;

&:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
}
`;

export const FollowUsCenter = styled.div`
    display: flex;
    vertical-align: bottom;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0.5rem;

      &:hover {
    transition: all 0.2s ease-in-out;
    color: #212121;
    cursor: pointer;
  }
`

export const FooterInfo = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    color: white;
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 1.5rem;
`


export const FooterSocialLink = styled(Link)`
    font-family: 'Source Sans Pro', sans-serif;
    color: white;
    font-size: 16px;
    text-decoration: none;
    margin-left: 5px;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #212121;
  }
`

export const FooterCopyright = styled.div`
    position: static;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #8C291B;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
    padding: 60px;
    padding-top: 10px;
    vertical-align: middle;
`

export const FooterCopyrightText = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    color: #FCFCFC;
`
