import styled from 'styled-components';

export const LocationColumnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media only screen and (max-width: 768px) {
         flex-direction: column-reverse;
    }
`

export const LocationColumnOne = styled.div`
    background-color: white;
    width: 50%;

    @media only screen and (max-width: 768px) {
         width: 100%;
    }
`
export const LocationColumnOneContainer = styled.div`
    margin: 60px;
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    height: auto;

    @media only screen and (max-width: 768px) {
         margin: 40px;
    }
`

export const LocationColumnOneH1 = styled.div`
    font-family: 'Crimson Text', serif;
    font-size: 40px;
    font-weight: 900;
    color: #333333;

    @media only screen and (max-width: 768px) {
         font-size: 2rem;
    }
`
export const LocationColumnOneP = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 20px 10px 20px 0px;
    color: #333333;
`

export const LocationTrainContainer = styled.div`
     display: flex;
     align-items: center;
     margin-bottom: 10px;
`

export const LocationTrainImage = styled.img`
    height: 30px;
`

export const LocationAddressPhone = styled.div`
    display: block;

`

export const LocationTrainP = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 18px;
    padding-left: 10px;
    color: #333333;
`

export const LocationRule = styled.hr`
    margin-top: 20px;
    margin-bottom: 5px;
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 1.0);
`

export const LocationColumnTwo = styled.iframe`
    background-color: white;
    width: 50%;
    padding: 20px;
    

     @media only screen and (max-width: 768px) {
         width: 100%; 
    }
`