import React from 'react'
import  GTrain from '../../images/gtrain.svg';
import  LTrain from '../../images/ltrain.svg';
import {LocationColumnContainer,
        LocationColumnOne,
        LocationColumnOneContainer,
        LocationColumnOneH1,
        LocationColumnOneP,
        LocationAddressPhone,
        LocationTrainContainer,
        LocationTrainImage,
        LocationTrainP,
        LocationRule,
        LocationColumnTwo,
        } from './LocationElements';

export const Location = () => {
    return (
        <LocationColumnContainer>

            <LocationColumnOne>
                    
                <LocationColumnOneContainer>

                    <LocationColumnOneH1> Location & Contact </LocationColumnOneH1>
                    <LocationRule />

                    <LocationAddressPhone>
                        <LocationColumnOneP>
                            119 Nassau Ave,<br />
                            Brooklyn, NY 11222
                        </LocationColumnOneP>

                        <LocationColumnOneP>  
                            718 389 3757 <br />
                            ambersteakhouse@gmail.com 
                        </LocationColumnOneP>
                    </LocationAddressPhone>
                            

                    <LocationColumnOneP>
                         We are located in Greenpoint, Brooklyn. We are a five minute walk from McCarren park. 
                        With MTA subway service you can get the: 
                    </LocationColumnOneP>

                    <LocationTrainContainer>
                    <LocationTrainImage src={GTrain}></LocationTrainImage> <LocationTrainP> to Nassau Ave or <br/> </LocationTrainP>
                    </LocationTrainContainer>

                    <LocationTrainContainer>
                        <LocationTrainImage src={LTrain} /> <LocationTrainP> to Bedford Ave and walk 10 minutes </LocationTrainP>
                    </LocationTrainContainer>

                   
                     
                    

                    

                </LocationColumnOneContainer>

            </LocationColumnOne>

            <LocationColumnTwo 
            width={500} height={500} frameBorder={0} scrolling="no" 
            marginHeight={0} marginWidth={0} id="gmap_canvas" 
            src="https://maps.google.com/maps?width=500&height=500&hl=en&q=119%20Nassau%20Ave%20Brooklyn+(Amber%20Steak%20House)&t=p&z=16&ie=UTF8&iwloc=B&output=embed" />
            
        </LocationColumnContainer>
    )
}

export default Location;
