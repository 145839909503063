import styled from 'styled-components';
// import { Link } from 'react-router-dom';


export const GalleryContainer = styled.div`
background-attachment: fixed;
    

    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%; */
`


export const GalleryUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  @media (max-aspect-ratio: 1/1) and (max-width: 480px) {
      flex-direction: row;
  }

`

export const GalleryLi = styled.li`
  height: 40vh;
  flex-grow: 1;
/* 
    li:last-child  */
    &:last-child {
    // There's no science in using "10" here. In all my testing, this delivered the best results.
    flex-grow: 10;
    }

    @media (max-aspect-ratio: 1/1) {
        height: 30vh;
    }

    @media (max-height: 480px) {
        height: 80vh;
    }

    @media (max-aspect-ratio: 1/1) and (max-width: 480px) {
        height: auto;
        width: 100%;
    }

`

export const GalleryImg = styled.img`
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;

  @media (max-aspect-ratio: 1/1) and (max-width: 480px) {
    width: 100%;
    max-height: 75vh;
    min-width: 0;
  }
`




