import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import LocationPage from '../components/LocationPage';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';


function Location() {

   const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
       <LocationPage />
      <Footer />
    </>
  );
}

export default Location;
