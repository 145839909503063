import styled from 'styled-components';

export const HoursContainer = styled.div`
    background: #8C291B;
    padding: 20px 100px;
    height: 350px;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 768px) {
        height: auto;
        padding-bottom: 50px;
        padding: 20px 30px;
        
    }
`;

export const MenuHoursTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 140px;
    
`

export const HoursTitle = styled.h1`
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    color: white;
    font-size: 70px; 

    @media only screen and (max-width: 768px) {
        font-size: 50px;
    }
`;

export const HoursRule = styled.hr`
    margin: 0 auto;
    margin-top: 40px;
    width: 70%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
`

export const HoursColumnContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`

export const HoursColumnLeft = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    float: left;
    width: 33.333%;
    
    @media only screen and (max-width: 768px) {
        float: none;
        width: 100%;
    }
`

export const HoursColumnCenter = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    float: right;
    width: 33.333%;

    @media only screen and (max-width: 768px) {
        float: none;
        width: 100%;
    }
`

export const HoursColumnRight = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    width: 33.333%;

    @media only screen and (max-width: 768px) {
        float: none;
        width: 100%;
    }
`

export const HoursInfoH1 = styled.h1`
    white-space: nowrap;
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    color: white;
    font-size: 24px; 
    margin: 20px;
    
`

export const HoursInfoP = styled.p`
    white-space: nowrap;
    font-family: 'Crimson Text', serif;
    font-weight: 400;
    color: white;
    font-size: 24px; 
`