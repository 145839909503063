import React from 'react';
import {SidebarContainer, 
        Icon, 
        CloseIcon, 
        SidebarWrapper, 
        SidebarMenu, 
        SidebarLink,
        SideBtnWrap,
        SidebarRoute,
        SidebarLogoLink,
        SidebarLogoImage } from './SidebarElements';
import  AmberLogo  from '../../images/amber.svg';


const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>

            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>

            <SidebarWrapper>

                <SidebarMenu>
                    <SidebarLogoLink to="/" onclick={toggle}>
                        <SidebarLogoImage src={ AmberLogo} />
                    </SidebarLogoLink>
                    
                    <SidebarLink to='menu' onClick={toggle}>Menu</SidebarLink>
                    <SidebarLink to='about' onClick={toggle}>About</SidebarLink>
                    <SidebarLink to='location' onClick={toggle}>Location & Contact</SidebarLink>
                </SidebarMenu>

                <SideBtnWrap>
                    <SidebarRoute onClick={()=>window.open("https://www.opentable.com/amber-steak-house?ref=1068", "_blank")}> Reserve a Table </SidebarRoute>
                </SideBtnWrap>

            </SidebarWrapper>

        </SidebarContainer>
    )
}

export default Sidebar;
