import styled from 'styled-components';
import AmberInterior from '../../images/amberinterior.png';


export const AboutHero = styled.div`
    /* background-image: linear-gradient( to top right, rgba(11, 10, 10, 0.38), rgba(11, 10, 10, 0.19)), url(${AmberInterior}); */
    background-color: #8C291B;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
`;

export const AboutHeroTextContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFEFE;

    @media only screen and (max-width:375px) {
        text-align: start;
        height: 80%;
    }
`;

export const AboutHeroH1 = styled.h1`
    color: #fff;
    font-size: 70px;
    text-align: center;
    font-family: 'Crimson Text', serif;

    @media screen and (max-width: 768px) {
        font-size: 50px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const AboutContainer = styled.div`
    background-color: white;
`

export const AboutHeader = styled.div`
    background-color: #f1f1f1;
    padding: 30px;
    text-align: center;
    font-size: 35px;
`

export const AboutHeaderH1 = styled.h1`
    font-family: 'Crimson Text', serif;
    color: #212121;
    font-weight: 600;
    font-size: 42px;
    margin: 10px;
`

export const AboutColumnContainer = styled.div`
    display: -webkit-flex;
    display: flex;
    height: auto;
     @media only screen and (max-width: 768px) {
         flex-direction: column;
    }
`

export const AboutColumnLeft = styled.div`
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 10px;
    background-color: white;

    @media only screen and (max-width: 768px) {
         display: none;
    }

`

export const AboutColumnCenter = styled.div`
    width: 60%;
    height: auto;
    /* -webkit-flex: 2;
    -ms-flex: 2;
     flex: 2; */
     padding: 40px;
     background-color: white;

     @media only screen and (max-width: 768px) {
         width: 100%;
         height: auto;
         padding: 20px;
    }
`

export const AboutColumnCenterContainer = styled.div`
    margin: 20px;
    height: auto;

    @media only screen and (max-width: 768px) {
         margin-left: 30px;
         margin-right: 30px;
    }
`

export const AboutP1 = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    color: #212121;
    font-weight: 400;
    font-size: 18px;
`

export const AboutP2 = styled.p`
    padding-top: 30px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #212121;
    font-weight: 400;
    font-size: 18px;
`
export const AboutColumnRight = styled.div`
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 10px;
    background-color: white;

    @media only screen and (max-width: 768px) {
         display: none;
    }
`