import React, { useState } from 'react';
import { Button } from '../ButtonElements';
import { MobileMenuContainer,
         MobileMenuH1,
         MobileMenuP,
         MobileMenuBtnWrapper,
         MobileMenuHR,
         MobileMenuImage,
        MobileMenuTitleBox } from './MobileMenuElements';
import  AmberWingOne from '../../images/amberwing1.svg';
import  AmberWingTwo from '../../images/amberwing2.svg';
import {  ArrowForward, ArrowRight } from '../../components/HeroSection/HeroElements';

const MobileMenu = () => {

     const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover (!hover)
    }


    return (
        <MobileMenuContainer>
            <MobileMenuTitleBox>
                <MobileMenuImage src={AmberWingOne} /> 
                    <MobileMenuH1>
                        Our Menu
                    </MobileMenuH1>
                <MobileMenuImage src={AmberWingTwo} /> 
            </MobileMenuTitleBox>

            <MobileMenuHR />
            <MobileMenuP>
                    Our kitchen is a full two-stories, equipped with three refrigerators for beer, 
                    seafood, vegetables and steaks. Our state-of-the-art kitchen also boasts smokers, 
                    steamers and broilers on which the chef can cook multiple steaks at once.
            </MobileMenuP>
            
            <MobileMenuBtnWrapper>
                <Button 
                        to='/menu'
                        onMouseEnter={onHover} 
                        onMouseLeave={onHover}> 
                        Menu { hover ? <ArrowForward /> : <ArrowRight />} 
                </Button>
            </MobileMenuBtnWrapper>

        </MobileMenuContainer>
    )
}

export default MobileMenu;
