import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import About from '../components/About';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';


function AboutPage() {

   const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
     <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
       <About />
      <Footer />
    </>
  );
}

export default AboutPage;
