import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
// import ScrollUpButton from "react-scroll-up-button";
import ScrollToTop from '../components/ScrollToTop';



function MenuPage() {

   const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Menu />
      <Footer />
      {/* <ScrollUpButton /> */}
    </>
  );
}

export default MenuPage;
