import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';


export const MenuHero = styled.div`
    background: #8C291B;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 300px;
    position: relative;
    z-index: 1;
`;

export const MenuHeroTitle = styled.h1`
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    color: white;
    font-size: 100px; 

    @media only screen and (max-width: 768px) {
        font-size: 70px;
    }
`;

export const MenuContentContainer = styled.div`
    scroll-behavior: smooth;
    overflow: auto;
`

export const MenuTopBar = styled.div`
    display: none;

    @media only screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        width: 100%;        
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
    }
`

export const TopBarItem = styled.div`
        padding: 10px;
        border-bottom: 1px solid #e4e4e4 !important;
        border-right: 1px solid #e4e4e4 !important;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-grow: 4;

        &:hover {
        background-color: #f1f1f1;
        transition: all 0.3s linear;
    }
`
    
export const TopBarBtn = styled(LinkS)`
        font-family: 'Crimson Text', serif;
        color: #212121;
        font-size: 18px;
        padding: 8px 19px;
        vertical-align: middle;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
`

export const MenuSidebar = styled.div`
    /* transform: translateZ(0);
    -webkit-transform: translateZ(0) */
    /* background-color: yellow; */
    /* overflow: auto; */
    position: absolute;    
    height: calc(100vh - 80px);
    flex-grow: 1;
    width: 20%;
    display: flex;
    flex-direction: column;
    text-align: center;
    
    border-right: 1px solid #e4e4e4;
    box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.08);
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08) , 0 2px 10px 0 rgba(0, 0, 0, 0.08);  */

    @media only screen and (max-width: 768px) {
        display: none;
    }
`

export const MenuSidebarItem = styled.div`
    width: 100%;
    /* display: block; */
    /* padding: 8px 16px; */
    flex-grow: 1;
    /* text-align: left; */
    border: none;
    white-space: normal;
    float: none;
    outline: 0;
    color: black;
    border-bottom: 1px solid #e4e4e4 !important;
    display:flex;
    align-items:center;
    justify-content:center;
    flex:1;
    
    &:hover {
        background-color: #f1f1f1;
        transition: all 0.3s linear;
    }

    &:active {
        background-color: #f1f1f1;
    }
`

export const SidebarBtn = styled(LinkS)`
    font-family: 'Crimson Text', serif;
    color:#212121;
    font-size: 18px;
    border: none;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
`

export const MenuContent = styled.div`
    margin-left: 20%;

    @media only screen and (max-width: 768px) {
         margin-left: 0%;
    }
`

export const MenuTitleBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    border-top: 1px solid #e4e4e4 !important;
    border-bottom: 1px solid #e4e4e4 !important;

`

export const MenuTitleBoxH1 = styled.h1`
    font-family: 'Crimson Text', serif;
    color: #212121;
    font-weight: 600;
    font-size: 42px;
    margin: 10px;

    @media only screen and (max-width: 768px) {
        font-size: 32px;
    }
`

export const MenuTitleBoxImage = styled.img`
    height: 18px;

    @media only screen and (max-width: 768px) {
        height: 15px;
    }
`

export const MenuCategoryDescription = styled.div`
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    @media only screen and (max-width: 768px) {
        margin-left: 0%;
        padding: 30px 40px 30px 40px;
    }
`

export const MenuTitleDescription = styled.div`
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-left: 20%; */

    @media only screen and (max-width: 768px) {
        margin-left: 0%;
        padding: 30px 40px 30px 40px;
    }
`

export const MenuTitleDescriptionP = styled.p`
    font-family: 'Crimson Text', serif;
    color: #212121;
    font-weight: 400;
    font-size: 18px;
`

export const MenuWhiskeyDescriptionP = styled.p`
    margin-top: 10px;
    font-family: 'Crimson Text', serif;
    color: #212121;
    font-weight: 400;
    font-size: 18px;
`

export const MenuTitleDescriptionH1 = styled.h1`
    font-family: 'Crimson Text', serif;
    color: #212121;
    font-weight: 600;
    font-size: 32px;
`    

export const MenuColumnHR = styled.hr`
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    
`

export const MenuCategoryHR = styled.hr`
    margin: 0 auto;
    margin-top: 20px;
    width: 60%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(175, 175, 175, 0), rgba(175, 175, 175, 0.75), rgba(0, 0, 0, 0));
`

export const MenuColumnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin-left: 20%; */
    margin-bottom: 20px;

    @media only screen and (max-width: 768px) {
         flex-direction: column;
         margin-left: 0%;
    }
`

export const MenuColumnOne = styled.div`
    width: 50%;
    margin-bottom: 0px;

    @media only screen and (max-width: 768px) {
         width: 100%;
    }
`

export const MenuColumnTwo = styled.div`
    width: 50%;

    @media only screen and (max-width: 768px) {
         width: 100%;
    }
`

export const MenuItemContainer = styled.div`
    margin: 30px;

    @media only screen and (max-width: 768px) {
         margin-top: 0px;
    }
`

export const MenuItem = styled.div`
    margin: 20px 10px 20px 10px;
`

export const MenuItemDish = styled.p`
    font-family: 'Crimson Text', serif;
    color: #212121;
    font-weight: 700;
    font-size: 22px;
`

export const MenuWineYear = styled.span`
    font-family: 'Crimson Text', serif;
    color: #212121;
    font-weight: 300;
    font-size: 22px;
    margin-left: 20px;
`
export const MenuItemPrice = styled.span`
    float: right !important;
    font-family: 'Crimson Text', serif;
`
export const MenuItemDescription = styled.p`
    font-family: 'Crimson Text', serif;
    font-weight: 400;
    color: #212121;
    font-weight: 400;
    font-size: 18px;
`

