import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`

/* font-family: 'Crimson Text', serif; */
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

/* font-family: 'Source Sans Pro', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');

/* font-family: 'Archivo Narrow', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap');

/* font-family: 'Gothic A1', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap');


:root{

  /* Main Colors */
    
  --clr-amber: #8C291B;
  --clr-light-gray: #F9FAFB;
  --clr-mid-gray: #FBFCFC;
  --clr-dark-gray: #E0E1E1;
  --clr-cream: #FFFAF2;


  /* Font */

  --ff-title: 'Crimson Text', serif;
  --ff-primary: 'Encode Sans Expanded', sans-serif;
  --ff-default: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  /* General */
  --transition: all 0.3s linear;
  --spacing: 2px;
  --radius: 3px;
  --max-width: 1170px;

}

`;
 
export default GlobalStyle;