import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const MobileMenuContainer = styled.div`
    padding: 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (min-width: 768px) {
         display: none;
    }
`

export const MobileMenuTitleBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const MobileMenuH1 = styled.div`
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    color: #333333;
    /* font-size: 70px; */
    font-size: 2.5rem;
    text-align: center;
    

`
export const MobileMenuP = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    margin: 15px 30px 10px 30px;
    color: #333333;
`

export const MobileBtnLink = styled(LinkR)`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  /* text-transform: uppercase; */
  /* letter-spacing: 1px; */
  border-radius: 50px;
  background: white;
  white-space: nowrap;
  padding: 10px 22px;
  color: black;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #333333;
    color: #FFF;
  }
`;

export const MobileMenuBtnWrapper = styled.div`
    margin: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const MobileMenuHR = styled.hr`
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 60%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(175, 175, 175, 0), rgba(175, 175, 175, 0.75), rgba(0, 0, 0, 0));
`



export const MobileMenuImage = styled.img`
    height: 15px;
    margin: 8px;
`