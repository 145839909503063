import { useEffect } from 'react';

const useScript = (src, id) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    if (id) {
      script.id = id;
    }
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src, id]);
};

export default useScript;