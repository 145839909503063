import React from 'react';
import { VscMenu } from 'react-icons/vsc';
import  AmberLogo  from '../../images/amber.svg';
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  NavBtn,
  NavLinkPage,
  NavBtnLink,
  NavLogoImage
} from './NavbarElements';

const Navbar = ({toggle}) => {
  
  return (
    <>
        <Nav>

          <NavbarContainer>
            
            <NavLogo to='/'>
              <NavLogoImage src={AmberLogo}   alt="Amber Logo" />
            </NavLogo>

            <MobileIcon onClick = {toggle}>
              <VscMenu />
            </MobileIcon>
            
            <NavMenu>
              
              <NavItem>
                <NavLinkPage to='/menu'> Menu </NavLinkPage>
              </NavItem>

              <NavItem>
                <NavLinkPage to='/about'> About </NavLinkPage>
              </NavItem>

              <NavItem>
                <NavLinkPage to='/location'> Location & Contact </NavLinkPage>
              </NavItem>

              

            </NavMenu>

            <NavBtn>

            <NavBtnLink onClick={()=>window.open("https://www.opentable.com/amber-steak-house?ref=1068", "_blank")}> Reserve a Table </NavBtnLink>
              
            </NavBtn>

          </NavbarContainer>

        </Nav>
    </>
  );
};

export default Navbar;
