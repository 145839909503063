import styled from 'styled-components';

export const LocationPageHero = styled.div`
    background-color: #8C291B;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;

    @media only screen and (max-width:768px) {
        height: 25vh;
    }
`;

export const LocationPageHeroTextContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFEFE;

    @media only screen and (max-width:375px) {
        text-align: start;
        height: 80%;
    }
`;


export const LocationPageHeroH1 = styled.h1`
    color: #fff;
    font-size: 70px;
    text-align: center;
    font-family: 'Crimson Text', serif;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const LocationPageContainer = styled.div`
    background-color: white;
`

export const LocationPageHeader = styled.div`
    background-color: #f1f1f1;
    padding: 30px;
    text-align: center;
    font-size: 35px;
`

export const LocationPageHeaderH1 = styled.h1`
    font-family: 'Crimson Text', serif;
    color: #212121;
    font-weight: 600;
    font-size: 42px;
    margin: 10px;
`

export const LocationPageColumnContainer = styled.div`
    display: -webkit-flex;
    display: flex;
    height: auto;
     @media only screen and (max-width: 768px) {
         flex-direction: column;
    }
`

export const LocationPageColumnLeft = styled.div`
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 10px;
    background-color: white;

    @media only screen and (max-width: 768px) {
         display: none;
    }

`

export const LocationPageColumnCenter = styled.div`
    width: 60%;
    height: auto;
    /* -webkit-flex: 2;
    -ms-flex: 2;
     flex: 2; */
     padding: 10px;
     background-color: white;

     @media only screen and (max-width: 768px) {
         width: 100%;
         height: auto;
    }
`

export const LocationPageColumnCenterContainer = styled.div`
    margin: 20px;
    height: auto;

    @media only screen and (max-width: 768px) {
         margin-left: 30px;
         margin-right: 30px;
    }
`

export const LocationPageP1 = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    color: #212121;
    font-weight: 400;
    font-size: 18px;
`

export const LocationPageP2 = styled.p`
    padding-top: 30px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #333333;
    font-weight: 400;
    font-size: 18px;
`
export const LocationPageColumnRight = styled.div`
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 10px;
    background-color: white;

    @media only screen and (max-width: 768px) {
         display: none;
    }
`

// ----------------------

export const LocationColumnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media only screen and (max-width: 768px) {
         flex-direction: column;
    }
`

export const LocationColumnOne = styled.div`
    background-color: white;
    width: 50%;

    @media only screen and (max-width: 768px) {
         width: 100%;
    }
`
export const LocationColumnOneContainer = styled.div`
    margin: 60px;
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    height: auto;

    @media only screen and (max-width: 768px) {
         margin: 40px;
    }
`

export const LocationColumnOneH1 = styled.div`
    font-family: 'Crimson Text', serif;
    font-size: 32px;
    font-weight: 900;
    color: #333333;

    @media only screen and (max-width: 768px) {
         font-size: 26px;
    }
`
export const LocationColumnOneP = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 20px 10px 20px 0px;
    color: #333333;
`

export const LocationTrainContainer = styled.div`
     display: flex;
     align-items: center;
     margin-bottom: 10px;
`

export const LocationTrainImage = styled.img`
    height: 30px;
`

export const LocationAddressPhone = styled.div`
    display: block;

`

export const LocationTrainP = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 18px;
    padding-left: 10px;
    color: #333333;
`

export const LocationRule = styled.hr`
    margin-top: 20px;
    margin-bottom: 5px;
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 1.0);
`

export const LocationColumnTwo = styled.iframe`
    background-color: white;
    width: 50%;
    padding: 20px;
    

     @media only screen and (max-width: 768px) {
         width: 100%; 
    }
`

export const HoursContainer = styled.div`
    background: #8C291B;

     outline: 4px solid #8C291B;
      border: 4px solid white;
      background-color: #8C291B;
      margin: 40px;

    padding: 20px 100px;
    height: 350px;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 768px) {
        height: auto;
        padding-bottom: 50px;
        padding: 20px 30px;
        
    }
`;

export const MenuHoursTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 140px;
    
`

export const HoursTitle = styled.h1`
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    color: white;
    font-size: 70px; 

    @media only screen and (max-width: 768px) {
        font-size: 50px;
    }
`;

export const HoursRule = styled.hr`
    margin: 0 auto;
    margin-top: 40px;
    width: 70%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
`

export const HoursColumnContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`

export const HoursColumnLeft = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    float: left;
    width: 33.333%;
    
    @media only screen and (max-width: 768px) {
        float: none;
        width: 100%;
    }
`

export const HoursColumnCenter = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    float: right;
    width: 33.333%;

    @media only screen and (max-width: 768px) {
        float: none;
        width: 100%;
    }
`

export const HoursColumnRight = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    width: 33.333%;

    @media only screen and (max-width: 768px) {
        float: none;
        width: 100%;
    }
`

export const HoursInfoH1 = styled.h1`
    white-space: nowrap;
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    color: white;
    font-size: 24px; 
    margin: 20px;
    
`

export const HoursInfoP = styled.p`
    white-space: nowrap;
    font-family: 'Crimson Text', serif;
    font-weight: 400;
    color: white;
    font-size: 24px; 
`