import styled from 'styled-components';
import{ MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';
import { Link as LinkR } from 'react-router-dom';
import  HeroImg from '../../images/restauraunt.jpg';

export const HeroContainer = styled.div`
    /* background: 
	height: 75vh;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
    background-attachment: fixed; */

    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.7)), url(${HeroImg});
    background-size: cover;
  
    background-position: center;
    /* background-blend-mode: overlay; */
    z-index: 1;

    background-attachment: fixed;

/*     
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    position: relative;
    z-index: 1; */
`

export const HeroContent = styled.div`
    padding: 40px 24px;
	color: white;

    display: flex;
  justify-content: center;
  align-items: center;

    flex-direction: column; 
`;

export const HeroH1 = styled.h1`
    color: #fff;
    font-size: 70px;
    text-align: center;
    font-family: 'Crimson Text', serif;

    @media screen and (max-width: 768px) {
        font-size: 50px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const HeroP = styled.p`
font-family: 'Source Sans Pro', sans-serif; 
    margin-top: 18px;
    color: #fff;
    font-size: 32px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        margin-top: 16px;
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;
export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

     @media only screen and (max-width: 768px) {
         display: none;
    }
`;

export const ReservationBtnWrap = styled.div`
  margin: 40px;
  display: flex;
  justify-content: center;

   @media only screen and (min-width: 768px) {
         display: none;
    }
`;

export const ReservationRoute = styled(LinkR)`
  font-family: 'Source Sans Pro', sans-serif; 
  border-radius: 50px;
  background: #8C291B;
  white-space: nowrap;
  padding: 16px 64px;
  color: white;
  font-size: 22px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: white;
    color: #212121;
  }
`;

export const ArrowForward = styled(MdArrowForward)`
margin-left: 8px;
font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
margin-left: 8px;
font-size: 20px;
`;