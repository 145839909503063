import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AmberInterior from '../../images/amberinterior.png';

export const FollowContainer = styled.div`
`

export const FollowDesktop = styled.div`
outline: 4px solid #8C291B;
    margin: 50px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;

     @media only screen and (max-width: 800px) {
         display: none;
    }
`

export const Hero = styled.div`
    /* background-image: url(${AmberInterior}); */
       
    background-color: #8C291B;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    height: 400px;
    overflow: hidden;
    position: relative;
    width: 100%;

    :after {
        /* background-color: rgba(51, 51, 51, 0.9); */
        bottom: 0;
        content: '';
        left: -2000%;
        position: absolute;
        right: -2000%;
        top: 0;
        z-index: 1;
    }

@media screen and (min-width: 640px) {
    background-position: 50% 0;
}

`
export const HeroContainer = styled.div`
  box-sizing: border-box;
  height: auto;
  padding: 30px 80px;
  position: relative;
  z-index: 2;
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transition-duration: 1.2s;
  transition-duration: 1.2s;

@media screen and (min-width: 640px) {
    height: 100%;
}
`

export const HeroContainerP = styled.div`
    width: 600px;
`

export const HeroP = styled.p`
    color: white;
   font-family: 'Source Sans Pro', sans-serif;
    font-size: 22px;
    font-weight: 400;
    text-align: left;
`

export const HeroTitle = styled.h1`
  /* color: #333333; */
  color: white;
  font-family: 'Crimson Text',serif;
  font-size: 110px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.1;
  margin: 20px 0;
  text-align: left;
  width: 85%;
  white-space: nowrap;

`

export const FollowColumnOne = styled.div`
    height: 500px;
    width: 50%;

    @media only screen and (max-width: 768px) {
         width: 100%;
    }
`

export const FollowColumnOneContainer = styled.div`
    margin: 60px;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: column;
    height: auto;

    @media only screen and (max-width: 768px) {
         margin: 40px;
    }
`
export const FollowTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 140px;
`
export const FollowTitle = styled.h1`
    font-family: 'Source Sans Pro', serif;
    font-weight: 600;
    color: #8C291B;
    font-size: 50px; 

    @media only screen and (max-width: 768px) {
        font-size: 50px;
    }
`;

export const FollowTitleDescription = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-left: 20%; */

    @media only screen and (max-width: 768px) {
        margin-left: 0%;
        padding: 30px 40px 30px 40px;
    }
`

export const FollowTitleDescriptionP = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    color: #333333;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
`

export const FollowRule = styled.hr`
    margin: 0 auto;
    margin-top: 20px;
    width: 70%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.50 ), rgba(0, 0, 0, 0.2));
`

export const FollowInfoH1 = styled.h1`
    white-space: nowrap;
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    color: white;
    font-size: 24px; 
    margin: 20px;
    
`

export const FollowInfoP = styled.p`
    white-space: nowrap;
    font-family: 'Crimson Text', serif;
    font-weight: 400;
    color: white;
    font-size: 24px; 
`

export const FollowSocialContainer = styled.div`
    margin: 20px;
    margin-left: 0px;
    display: flex;
    flex-direction: row;
`
export const FollowUsCenter = styled(Link)`
    margin: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
     vertical-align: bottom;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;

    &:hover {
    transition: all 0.2s ease-in-out;
    color: #212121;
  }
`

export const FollowSocialLink = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    color: white;
    font-size: 25px;
    font-weight: bold;
    text-decoration: none;
    margin-left: 5px;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #212121;
  }
`

export const FollowColumnTwo = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 50%;

    @media only screen and (max-width: 768px) {
         width: 100%;
    }
`

export const FollowMobile = styled.div`
      outline: 4px solid #8C291B;
      border: 4px solid white;
      background-color: #8C291B;
      margin: 30px;
      
      

 @media only screen and (min-width: 768px) {
         display: none;
    }
`

export const FollowMobileTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 140px;
`
export const FollowMobileTitle = styled.h1`
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    color: white;
    font-size: 50px; 

    @media only screen and (max-width: 768px) {
        font-size: 40px;
    }
`;

export const FollowMobileTitleDescription = styled.div`
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-left: 20%; */

    @media only screen and (max-width: 768px) {
        margin-left: 0%;
        padding: 30px 40px 30px 40px;
    }
`

export const FollowMobileTitleDescriptionP = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    color: white;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
`

export const FollowMobileRule = styled.hr`
    margin: 0 auto;
    margin-top: 40px;
    width: 90%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.50 ), rgba(255, 255, 255, 0.2));
`

export const FollowMobileInfoH1 = styled.h1`
    white-space: nowrap;
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    color: white;
    font-size: 24px; 
    margin: 20px;
    
`

export const FollowMobileInfoP = styled.p`
    white-space: nowrap;
    font-family: 'Crimson Text', serif;
    font-weight: 400;
    color: white;
    font-size: 24px; 
`

export const FollowMobileSocialContainer = styled.div`
    margin: 20px;
    display: flex;
    flex-direction: column;

     @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`
export const FollowUsMobileCenter = styled.div`
    margin: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
     vertical-align: bottom;
    justify-content: center;
    align-items: center;
    color: white;

     &:hover {
    transition: all 0.2s ease-in-out;
    color: #212121;
  }
`

export const FollowMobileSocialLink = styled(Link)`
    font-family: 'Source Sans Pro', sans-serif;
    color: #8C291B;
    font-size: 20px;
    text-decoration: none;
    margin-left: 5px;
    

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #212121;
    cursor: pointer;
  }
`