import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroStatic from '../components/HeroStatic';
import MobileMenu from '../components/MobileMenu';
import Hours from '../components/Hours';
import Location from '../components/Location';
import Gallery from '../components/Gallery';
import GalleryTwo from '../components/GalleryTwo';
import Follow from '../components/Follow';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';

// import InfoSection from '../components/InfoSection';
// import Services from '../components/Services';

const Home = () => {

    const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeroStatic />
            <MobileMenu />
            <Hours />
            <Gallery />
            <Location />
            <GalleryTwo />
            <Follow />
            <Footer />
        </>
    )
}

export default Home;