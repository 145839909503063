import React from 'react'
import {HoursContainer,
        MenuHoursTitleContainer,
        HoursTitle,
        HoursRule,
        HoursColumnContainer,
        HoursColumnLeft,
        HoursColumnCenter,
        HoursColumnRight,
        HoursInfoH1,
        HoursInfoP } from './HoursElements';


const Hours = () => {
    return (

        <HoursContainer> 


            <MenuHoursTitleContainer>
            <HoursTitle> Current Hours </HoursTitle>
            <HoursRule />
            </MenuHoursTitleContainer>

            <HoursColumnContainer> 

                <HoursColumnLeft> 
                    <HoursInfoH1>Monday - Thursday</HoursInfoH1>
                    <HoursInfoP> 1PM - 10PM </HoursInfoP>
                </HoursColumnLeft>
                    

                <HoursColumnCenter>
                        <HoursInfoH1> Friday - Saturday </HoursInfoH1>
                        <HoursInfoP> 1PM - 11PM </HoursInfoP>
                </HoursColumnCenter>

                <HoursColumnRight>
                        <HoursInfoH1> Sunday </HoursInfoH1>
                        <HoursInfoP> 1PM - 10PM </HoursInfoP>
                </HoursColumnRight>

            </HoursColumnContainer>


        </HoursContainer>
    )
}

export default Hours;
