import React, { useState } from 'react';
import Video from '../../videos/video.mp4';
import { Button } from '../ButtonElements';
import {
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  ReservationBtnWrap,
  ReservationRoute,
} from './HeroStaticElements';


const HeroStatic = () => {

    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover (!hover)
    }

    return (
        <HeroContainer>

            <HeroContent>
                <HeroH1> Established in 2010 </HeroH1>
                <HeroP>  We take great pride in being Greenpoint’s first and only classic steak house </HeroP>
                <HeroBtnWrapper>
                    <Button 
                    to='/menu'
                    onMouseEnter={onHover} 
                    onMouseLeave={onHover}> 
                    Menu { hover ? <ArrowForward /> : <ArrowRight />} 
                    </Button>
                </HeroBtnWrapper>

                <ReservationBtnWrap>
                    <ReservationRoute onClick={()=>window.open("https://www.opentable.com/amber-steak-house?ref=1068", "_blank")}> Reserve a Table </ReservationRoute>
                </ReservationBtnWrap>
            </HeroContent>

        </HeroContainer>
    )
}

export default HeroStatic;
