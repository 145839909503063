import React from 'react';
import GlobalStyle from './globalStyles.js';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages';
import MenuPage from './pages/menu';
import AboutPage from './pages/about';
import LocationPage from './pages/location';

function App() {
  return (
    
    <Router>
   
        <GlobalStyle />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/menu" component={MenuPage} exact/>
          <Route path="/about" component={AboutPage} exact/>
          <Route path="/location" component={LocationPage} exact/>
        </Switch>
    
    </Router>



   
  
    
  );
}



export default App;


