import React from 'react'
import { AiFillFacebook } from 'react-icons/ai';
import { FaInstagramSquare } from 'react-icons/fa';
import {  FollowContainer,

          FollowDesktop,

          Hero,
          HeroContainer,
          HeroContainerP,
          HeroP,
          HeroTitle,
         

          FollowColumnOne,
          FollowColumnOneContainer,
          FollowTitleContainer,
          FollowTitle,
          FollowTitleDescription,
          FollowTitleDescriptionP,
          FollowRule,
          FollowInfoH1,
          FollowInfoP,
          FollowSocialContainer,
          FollowUsCenter,
          FollowSocialLink,
          FollowColumnTwo,




          FollowMobile,
          FollowMobileTitleContainer,
          FollowMobileTitle,
          FollowMobileTitleDescription,
          FollowMobileTitleDescriptionP,
          FollowMobileRule,
          FollowMobileInfoP,
          FollowMobileSocialContainer,
          FollowUsMobileCenter,
          FollowMobileSocialLink
        } from './FollowElements';

const index = () => {
    return (
        <FollowContainer> 

        <FollowDesktop> 
          <Hero>
            <HeroContainer>
              
              <HeroTitle>
                Follow us!
              </HeroTitle>

              <HeroContainerP>
                <HeroP>
                  Visit our instagram and facebook to 
                      see the latest photos of our delicious food and updates
                      on whats going on.
                </HeroP>
              </HeroContainerP>

              <FollowSocialContainer>
                      <FollowUsCenter>
                    <FaInstagramSquare size={40} />  <FollowSocialLink onClick={()=>window.open("https://www.instagram.com/ambersteakhouse/", "_blank")}>  Instagram </FollowSocialLink>
                    </FollowUsCenter>

                    <FollowUsCenter>
                      <AiFillFacebook size={40} /> <FollowSocialLink onClick={()=>window.open("https://www.facebook.com/Amber-Steak-House-153555004746418/", "_blank")}>  Facebook </FollowSocialLink>
                    </FollowUsCenter>
                  </FollowSocialContainer>    
            </HeroContainer>
          </Hero>

        </FollowDesktop>

        <FollowMobile>
          <FollowColumnOneContainer>
            <FollowMobileTitleContainer>
              <FollowMobileTitle>Follow Us!</FollowMobileTitle>
              <FollowMobileRule />  
            </FollowMobileTitleContainer>
              <FollowMobileTitleDescription>
                  <FollowMobileTitleDescriptionP>
                      Visit our instagram and facebook to 
                      see the latest photos of our delicious food and updates
                      on whats going on.
                  </FollowMobileTitleDescriptionP>
                      <FollowMobileSocialContainer>
                              <FollowUsMobileCenter>
                            <FaInstagramSquare size={40} />  <FollowSocialLink onClick={()=>window.open("https://www.instagram.com/ambersteakhouse/", "_blank")}>  Instagram </FollowSocialLink>
                            </FollowUsMobileCenter>

                            <FollowUsMobileCenter>
                              <AiFillFacebook size={40} /> <FollowSocialLink onClick={()=>window.open("https://www.facebook.com/Amber-Steak-House-153555004746418/", "_blank")}>  Facebook </FollowSocialLink>
                            </FollowUsMobileCenter>
                      </FollowMobileSocialContainer>
              </FollowMobileTitleDescription>
          </FollowColumnOneContainer>
        </FollowMobile>
      
        </FollowContainer>
    )
}

export default index
